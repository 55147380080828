* {
  box-sizing: border-box;
}

body {
  background-color: #f2f2f2;
  color: #404040;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 20px;
}

h2 {
  margin-top: -10px;
  margin-bottom: 10px;
  color: #65676c;
}

h3 {
  font-weight: 500;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

table {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-collapse: collapse;
  width: 100%;
}

.rdtPicker table {
  border: none;
}

table thead tr th {
  border-bottom: 1px solid #e6e6e6;
  font-weight: 600;
  text-align: left;
  padding: 12px;
}

.rdtPicker table thead tr th {
  border: none;
  padding: 4px;
}

table tbody tr:hover {
  background-color: #f9f9f9;
}

.rdtPicker table tbody tr:hover {
  background-color: transparent;
}

table tbody tr td {
  border-top: 1px solid #e6e6e6;
  padding: 12px;
  vertical-align: text-top;
}

.rdtPicker table tbody tr td {
  border: none;
  padding: 4px;
}

table tbody tr:last-child td {
  border-bottom: none;
}

.rounded {
  border-radius: 5px;
}

iframe {
  border: none;
  width: 100%;
  overflow: hidden;
}

.Select-control {
  border: 1px solid #d9d9d9;
}

.is-open > .Select-control,
.is-focused:not(.is-open) > .Select-control {
  border-color: #5492c4;
  box-shadow: none;
}

.rbc-calendar {
  min-height: 550px;
}

.rbc-toolbar-label,
.rbc-btn-group {
  font-size: 13px;
}

.rbc-event-content {
  font-size: 12px;
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-toolbar button,
.rbc-month-view,
.rbc-time-view,
.rbc-month-row + .rbc-month-row,
.rbc-day-bg + .rbc-day-bg,
.rbc-time-content > * + * > *,
.rbc-time-header > .rbc-row > * + * {
  border-color: #e6e6e6;
}

.rbc-toolbar button.rbc-active {
  border-color: #d9d9d9;
  box-shadow: none;
}

#quick-help h2 {
  font-weight: normal;
  font-size: 18px;
  margin-top: -10px;
  margin-bottom: 10px;
  color: #65676c;
}

#quick-help h2 i {
  color: #65676c;
}

#quick-help p {
  margin-top: 0;
  margin-bottom: 10px;
}

.bubble {
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 6px #ccc;
  margin-bottom: 15px;
  padding: 10px 18px;
  display: inline-block;
}

video {
  width: 100%;
}

.call-pea {
  color: #900;
}

.notifications-tr .notification {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2) !important;
  height: auto !important;
  padding: 15px 20px 20px 20px !important;
}

.notification-dismiss {
  top: 8px !important;
  right: 8px !important;
}

button {
  cursor: pointer;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  width: 100% !important;
}

.react-datepicker__input-container input {
  outline: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #333;
  font-size: 14px;
  width: 100%;
  padding: 8px 10px;
}

a[download] {
  color: #187fb5;
  text-decoration: none;
}

.selection-item {
  border: 1px dashed #ccc;
  transition: all 0.5s;
}

.selection-item.active {
  border: 1px solid #1075b0;
  background-color: #21a0e7;
}

.selection-item.selected {
  border: 1px solid #1075b0;
  background-color: #21a0e7;
}

.selection-area {
  border: 1px solid rgba(0, 0, 255, 0.2);
  background: rgba(0, 0, 255, 0.1);
}

.rbc-time-view .rbc-header {
  border-bottom: 0;
}

.rbc-allday-cell {
  display: none;
}

.fc-showAll-button {
  background: #3d7db0 !important;
  border-color: #3d7db0 !important;
}
